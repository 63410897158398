@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');
@import url('https://fonts.googleapis.com/css?family=Roboto');

@font-face {
  font-family: 'Arctic';
  src: url('../public/fonts/Arctic.ttf');
}

@layer base {
  html,
  body,
  #root {
    @apply h-full;
  }

  body {
    @apply bg-white text-black font-sans;
  }

  a {
    @apply no-underline cursor-pointer font-bold text-blue hover:text-blue-dark;
  }

  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl;
  }
  h4 {
    @apply text-2xl;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply font-normal font-arctic text-black;
  }
}
